export default {
  resolved: new Map(),
  nsResolve(domain) {
    if (!this.resolved.has(domain)) {
      this.resolved.set(domain, fetch(`https://dns.google/resolve?name=${domain}&type=2`, {
        referrerPolicy: 'no-referrer',
        cache: 'no-cache',
      }).then((response) => {
        return response.json();
      }).then((jsonResponse) => {
        let ns = [];
        if (jsonResponse.Answer) {
          for (const a of jsonResponse.Answer) {
            if (a.type == 2) {
              ns.push(a.data);
            }
          }
        }
        if (
          ns.length === 0 &&
          (domain.split('.').length > 2) &&
          jsonResponse.Authority &&
          jsonResponse.Authority.length > 0
        ) {
          ns.push(jsonResponse.Authority[0].data.split(". ", 2)[0]);
        }
        return ns;
      }).catch(() => {
        return [];
      }));
    }
    return this.resolved.get(domain);
  },
  nsStatus(domain, nameservers) {
    return this.nsResolve(domain).then((domainNs) => {
      if (domainNs.length === 0) {
        return "not-found"
      }
      for (const ns of domainNs) {
        if (!nameservers.includes(ns)) {
          return "mismatch";
        }
      }
      return "ok";
    });
  },
  aStatus(domain, ip_address) {
    return this.resolveARecord(domain).then((result) => {
      if (result === null) {
        return "not-found"
      }
      if (result != ip_address) {
        return "mismatch";
      }
      return "ok";
    });
  },
  resolveARecord(domain) {
    return fetch(`https://dns.google/resolve?name=${domain}&type=1`, {
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
    }).then((response) => {
      return response.json();
    }).then((jsonResponse) => {
      if (jsonResponse.Answer) {
        for (const a of jsonResponse.Answer) {
          if (a.type == 1) {
            return a.data;
          }
        }
      }
      return null;
    }).catch(() => {
      return null;
    });
  },
}