import { render, staticRenderFns } from "./OnboardingImportChooseServiceModal.vue?vue&type=template&id=ccb06dd6&scoped=true"
import script from "./OnboardingImportChooseServiceModal.vue?vue&type=script&lang=js"
export * from "./OnboardingImportChooseServiceModal.vue?vue&type=script&lang=js"
import style0 from "./OnboardingImportChooseServiceModal.vue?vue&type=style&index=0&id=ccb06dd6&prod&lang=scss&scoped=true"
import style1 from "./OnboardingImportChooseServiceModal.vue?vue&type=style&index=1&id=ccb06dd6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccb06dd6",
  null
  
)

export default component.exports