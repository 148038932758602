import Api from "@/apis/Api";
import Helper from "@/apis/Helper";
import moment from "moment";
import store from "../store/store";

export default {
  cached: new Map(),
  get(id) {
    if (this.cached.has(id)) {
      return new Promise((resolve) => {
        resolve(this.cached.get(id));
      });
    }
    return Api.get(`/instances/${id}`).then((response) => {
      let instance = this.instanceResource(response.data.data);
      this.cached.set(instance.id, instance);
      return instance;
    });
  },
  async list(
    page,
    perPage,
    sortBy,
    sortDesc = false,
    searchTerm = "",
    labels = [],
    serverAccountId = false
  ) {
    let sortDir = sortDesc ? "desc" : "asc";
    let url = `?page=${page}&per_page=${perPage}&sort_by=${sortBy}&sort_dir=${sortDir}${serverAccountId ? `&server_account_id=${serverAccountId}` : ""
      }`;
    if (searchTerm) {
      url += `&search_term=${searchTerm}`;
    }
    if (labels.length) {
      url += `&labels=${labels.join()}`;
    }

    try {
      let meta = {};
      let instances = await Api.get(`/instances${url}`).then((response) => {
        meta = response.data.meta;
        let instances = [];
        for (const app of response.data.data) {
          let instance = this.instanceResource(app);
          this.cached.set(instance.id, instance);
          instances.push(instance);
        }
        return instances;
      });

      return { data: instances, meta: meta, error: null };
    } catch (error) {
      console.error(error);
      return { data: null, error: Api.getErrorMessage(error) };
    }
  },
  //
  instanceResource(app) {
    let apiUrl = Api.defaults.baseURL;

    let subInstances = [];
    for (let subInstance of app.staging_apps) {
      subInstances.push(this.instanceResource(subInstance));
    }

    let parentInstance = null;
    if (app.parent_app) {
      parentInstance = this.instanceResource(app.parent_app);
    }

    let instance = {
      id: app.id,
      title: app.name,
      url: app.url,
      domain: app.domain,
      dir: app.dir,
      subInstances: subInstances,
      parentInstance: parentInstance,
      sharedAccess: app.shared_access,
      owner: app.owner,
      owned: app.owned,
      privileges: app.privileges,
      status: status,
      service_status: app.service_status,
      hostingId: app.server_account_id,
      hostingStatus: app.server_account_status,
      hostingName: app["plans.name"],
      staging: app.staging,
      colorScheme: app.staging ? "info" : "primary",
      subinstanceLabel: app.staging ? "staging" : "live",
      labels: app.labels ? app.labels : [],
      img: `${apiUrl}/instances/${app.id}/screenshot`,
      info: {
        ssl: app.url.startsWith("https://"),
        plugins: { errors: 0 },
        visitors: {
          current:
            app.stats && app.stats.visitors.unique
              ? app.stats.visitors.unique
              : "-",
          delta:
            app.stats && app.stats.visitors.unique_lastmonth
              ? Math.round(
                (-1 +
                  app.stats.visitors.unique /
                  app.stats.visitors.unique_lastmonth) *
                100
              ) + "%"
              : "-",
        },
        bandwidth: {
          current:
            app.stats && app.stats.bandwidth.usage
              ? Helper.formatBytes(app.stats.bandwidth.usage)
              : "-",
          max:
            app.plan_details !== null
              ? app.plan_details.max_bandwidth === -1
                ? "∞"
                : Helper.formatBytes(app.plan_details.max_bandwidth)
              : "-",
        },
        storage: {
          current:
            app.stats && app.stats.storage.usage
              ? Helper.formatBytes(app.stats.storage.usage)
              : "-",
          max:
            app.plan_details !== null
              ? app.plan_details.max_storage === -1
                ? "∞"
                : Helper.formatBytes(app.plan_details.max_storage)
              : "-",
        },
      },
      created_at: app.created_at,
      created_at_from_now: moment(app.created_at).fromNow(),
      theme_details: app.theme_details,
      performance_score: app.performance_score,
      backup_details: app.backup_details ? app.backup_details : [],
      plugin_details: app.plugin_details ? app.plugin_details : [],
      has_automatic_backup: app.has_automatic_backup,
      isActive: app.status == "active",
      service_has_dns: app.service_has_dns,
      service_has_dns_cloudflare: app.service_has_dns_cloudflare,
      install_details: app.install_details,
      imported: app.imported,
      details: app.details || [],
      async_status: {
        installation: app.async_status ? app.async_status.installation : null,
        update: app.async_status ? app.async_status.update : null,
        push_to_live: app.async_status ? app.async_status.push_to_live : null,
        pull_from_staging: app.async_status
          ? app.async_status.pull_from_staging
          : null,
      },
      host_ip_address: app.host_ip_address,
      dns_nameservers: app.dns_nameservers,
      debugMode: false,
      maintenanceMode: false,
      automatic_backups_editable: app.automatic_backups_editable,
      domain_has_dns_zone: app.domain_has_dns_zone,
      health_checks: app.health_checks,
      plan_settings: app.plan_settings,
      hasInstallInProgress() {
        return (
          this.async_status.installation == "pending" ||
          this.async_status.installation == "in_progress"
        );
      },
      hasInstallFailed() {
        return this.async_status.installation == "failed";
      },
      hasUpdateInProgress() {
        return (
          this.async_status.update == "pending" ||
          this.async_status.update == "in_progress"
        );
      },
      hasActionInProgress() {
        return (
          this.hasInstallInProgress() ||
          this.hasUpdateInProgress() ||
          this.async_status.push_to_live == "pending" ||
          this.async_status.push_to_live == "in_progress" ||
          this.async_status.pull_from_staging == "pending" ||
          this.async_status.pull_from_staging == "in_progress"
        );
      },
      hasInstallStatus() {
        return this.hasInstallInProgress() || this.hasInstallFailed();
      },
      setSharedAccessData(data) {
        this.sharedAccess = data;
        Api.instances().cached.set(this.id, this);
        store.$app.$root.$emit("instance-updated", this);
      },
      setDebugMode(status) {
        this.debugMode = status;
        Api.instances().cached.set(this.id, this);
      },
      setMaintenanceMode(status) {
        this.maintenanceMode = status;
        Api.instances().cached.set(this.id, this);
      },
      updateSiteName(name) {
        return Api.put(`/instances/${this.id}/wordpress/site-name`, {
          name: name,
        }).then((response) => {
          this.title = name;
          return response;
        });
      },
      unsetSubinstance(id) {
        this.subInstances = this.subInstances.filter((item) => {
          return item.id !== id;
        });
        Api.instances().cached.set(this.id, this);
      },
      setPluginsData(data) {
        this.status.woocommerce = null;
        this.status.woocommerce_available = null;
        for (const plugin of data) {
          if (plugin.name == "woocommerce") {
            this.status.woocommerce = plugin.version;
            this.status.woocommerce_available = plugin.update_version
              ? plugin.update_version
              : plugin.version;
            break;
          }
        }
        // this.status = status;
        let pluginErrors = 0;
        for (const plugin of data) {
          if (plugin.update == "available") {
            pluginErrors++;
          }
        }
        this.info.plugins.errors = pluginErrors;
        Api.instances().cached.set(this.id, this);
      },
      isNew() {
        return moment().diff(this.created_at, 'minutes') <= 5;
      },
      hasAutomaticBackup() {
        return this.has_automatic_backup;
      },
      setAutomaticBackup(enabled) {
        this.has_automatic_backup = enabled;
        Api.instances().cached.set(this.id, this);
      },
      hasRecentBackup() {
        let minDate = new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
        for (const backup of this.backup_details) {
          if(backup.type == 'automatic') {
            return true
          }
          if (backup?.async_status?.create != 'finished') {
            continue;
          }
          if (backup.date * 1000 > minDate) {
            return true;
          }
        }
        return false;
      },
      setBackupDetails(data) {
        this.backup_details = data;
        Api.instances().cached.set(this.id, this);
      },
      setBackupItem(item) {
        for (let [key, i] of this.backup_details.entries()) {
          if (i.id === item.id) {
            this.backup_details[key] = item;
            break;
          }
        }
        this.setBackupDetails([...this.backup_details]);
      },
      unsetBackupItem(item) {
        for (let [key, i] of this.backup_details.entries()) {
          if (i.id === item.id) {
            this.backup_details.splice(key, 1);
            break;
          }
        }
        Api.instances().cached.set(this.id, this);
      },
      setPluginItem(item) {
        for (let [key, i] of this.plugin_details.entries()) {
          if (i.name === item.name) {
            this.plugin_details[key] = item;
            break;
          }
        }
        this.setPluginsData([...this.plugin_details]);
      },
      getUserPrivileges(permission) {
        return this.privileges.indexOf(permission) > -1;
      },
      hasFeature(feature) {
        // TODO for end user privilege/feature means the same
        return this.privileges.includes(feature);
      },
    };

    let wordpress_available = app.version;
    if (
      app.update_details &&
      app.update_details.available_updates &&
      app.update_details.available_updates.length
    ) {
      wordpress_available = app.update_details.available_updates
        .sort()
        .reverse()[0];
    }

    instance.status = {
      value: instance.hasAutomaticBackup() && instance.hasRecentBackup() && app.version == wordpress_available,
      wordpress: app.version,
      wordpress_available: wordpress_available,
      woocommerce: null,
      woocommerce_available: null,
      backup: instance.hasAutomaticBackup(),
      backup_recent: instance.hasRecentBackup(),
    };

    if (app.plugin_details) {
      instance.setPluginsData(app.plugin_details);
    }

    return instance;
  }
};
