<template>
  <v-dialog
    hide-overlay
    :overlay-opacity="0"
    :transition="false"
    persistent
    ref="dialog"
    width="561px"
    :value="isOpen ? true : false"
    absolute
  >
    <div class="card-overlay" @click="$refs.dialog.animateClick()" />

    <v-card style="width: 561px" v-if="isOpen">
      <template>
        <v-card-title class="pb-0 flex justify-space-between align-center">
          <div class="d-flex flex-column">
            <span class="font-weight-light p-1 base--text">
              {{ $t('heading.onboarding.import.url.title') }}
            </span>
            <h4 class>{{ $t('heading.onboarding.import.url.subtitle') }}</h4>
          </div>
          <v-btn x-small icon @click="$emit('removeModal')" class="close-model">
            <v-icon>$close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text height="800px" class="px-10 pt-8">
          <h6 class="mb-2">{{ $t("heading.onboarding.chooseProduct.product.title") }}</h6>
          <p class="p-3" v-if="services.length > 1">
            {{ $t("heading.onboarding.chooseProduct.product.subtitle") }}
          </p>
          <v-form ref="form">
            <v-select
              outlined
              :items="services"
              v-model="selectedService"
              hide-details=""
              return-object
              validate-on-blur
              class="v-input--lg"
              item-value="id"
              :menu-props="{
                offsetY: true,
                nudgeBottom: '8px',
                closeOnContentClick: false,
                contentClass:
                  'custom-dropdown-select custom-dropdown-select--onboarding',
              }"
            >
              <template slot="selection" slot-scope="data">
                <template>{{ data.item.plan_name }} </template>
                <template>({{ $t('heading.onboarding.chooseProduct.product.instancesLimit', {count: data.item.instance_count, limit: data.item.instance_limit}) }})</template>
                <template v-if="data.item.hosting_accounts.length">
                  - {{ data.item.hosting_accounts[0].domain }}</template>
              </template>
              <template slot="item" slot-scope="data">
                <template>{{ data.item.plan_name }} </template>
                <template>({{ $t('heading.onboarding.chooseProduct.product.instancesLimit', {count: data.item.instance_count, limit: data.item.instance_limit}) }})</template>
                <template v-if="data.item.hosting_accounts.length">
                  - {{ data.item.hosting_accounts[0].domain }}</template>
              </template>
            </v-select>
            <v-scale-transition>
              <div
                v-if="selectedService.instance_count >= selectedService.instance_limit"
                class="warning-box my-5"
              >
                <v-icon color="warning" size="20" class="mr-2">$alertcircle</v-icon>
                <span class="warning--text">
                  {{ $t('heading.onboarding.chooseProduct.product.fullServiceAlert') }}
                </span>
              </div>
            </v-scale-transition>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-column pb-4 px-10 pt-2">
          <v-btn
            x-large
            elevation="0"
            @click="$emit('service-selected', selectedService)"
            color="primary"
            block
            :disabled="selectedService.instance_count >= selectedService.instance_limit"
          >
            <template>
              {{ $t("button.continue") }}
              <v-icon size="24" class="ml-2">$arrowright</v-icon>
            </template>
          </v-btn>
          <v-btn
            x-large
            elevation="0"
            class="mx-0 mt-2"
            @click="$emit('goBack')"
            color="gray"
            text
            block
          >
            <span class="p-1 font-weight-light gray--text text--darken-1">
              {{ $t("button.cancel") }}
            </span>
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import FormRulesMixin from "../../mixins/FormRulesMixin";

export default {
  mixins: [FormRulesMixin],

  data: function () {
    return {
      selectedService: { id: 0 },
    };
  },
  props: {
    services: Array,
    preSelectedService: Object,
    isOpen: [Boolean, String],
  },
  watch: {
    isOpen: function (open) {
      if (open) {
        this.$store.dispatch("lockBodyScroll");
        if (!this.preSelectedService.id && this.services.length === 1) {
          this.$emit("service-selected", this.services[0]);
          return;
        }
        if (this.preSelectedService.id) {
          this.selectedService = this.preSelectedService;
          return;
        }
        if (!this.preSelectedService.id && this.services.length > 1) {
          this.selectedService = this.services[0];
          return;
        }
      }
      this.$store.dispatch("unlockBodyScroll");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card::v-deep {
  .v-card__text {
    -webkit-line-clamp: unset !important;
  }
  @media (max-width: 480px) {
    .v-card__text,
    .v-card__actions {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}

.custom-dropdown-select--onboarding {
  display: none !important;
}

.v-input::v-deep {
  &.v-select.v-input--lg {
    .v-input__control .v-input__slot {
      min-height: 56px;
      max-height: fit-content;
      border-radius: 8px;
      input {
        display: none;
      }
    }
  }
}

.v-input::v-deep {
  &.v-input--lg {
    .v-input__control .v-input__slot {
      border-radius: 8px;
    }
  }
}

.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>

<style lang="scss">
.close-model {
  position: absolute;
  top: -40px;
  right: -8px;
}
.custom-dropdown-select--onboarding::v-deep {
  overflow-y: auto !important;
  padding-left: 16px;
  .v-list-item {
    color: unset !important;
    caret-color: unset !important;
  }
}
</style>